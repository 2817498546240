.about-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 1600px;
    margin-inline: auto;
    padding-block: 5rem;
}


.about-section-1,
.about-section-2 {
    display: flex;
    justify-content: space-between;
    border: 1px solid #eaeaec;
    column-gap: max(5%, 2rem);
}

.about-section-1 .img-right {
    max-width: min(50rem, 40vw);
}

.about-section-1 .img-right img {
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 4/3;
}

.about-section-1 .about-section-1-text,
.about-section-2 .about-section-2-text {
    padding: 2rem;
    flex: 1;
}

.about-section-2 .iframe-right {
    width: min(50rem, 40vw);
}