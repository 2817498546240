.footer-parent {
    background-color: #000;
    color: #fff;
    padding: 50px 20px 70px 20px;
}

.footer-parent>.row {
    max-width: 1500px;
    margin-inline: auto;
}

.footer-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 10px;
}

.footer-content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.footer-contact {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.footer-link a,
.footer-contact-type,
.footer-contact-value {
    text-decoration: none;
    color: #fff;
}

.copy-right {
    text-align: center;
    margin-top: 50px;
    font-size: 1.2rem;
}