.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
    background-color: #818a91;
    color: #fff
}

.elementor-widget-container,
.entry-content {
    padding-top: 150px;
    padding-inline: 20px;
    max-width: 1280px;
    margin: 0 auto;
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
    color: #818a91;
    border: 3px solid;
    background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
    margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
    width: 1em;
    height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
    float: left;
    text-align: center;
    line-height: 1;
    font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
    display: inline-block
}