.item-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 1fr));
    grid-gap: 20px;
}

@media (max-width: 767px) {
    .item-grid {
        grid-template-columns: repeat(1, minmax(300px, 1fr));
    }
}

.category-bar {
    position: fixed;
    display: flex;
    align-items: center;
    margin: 20px 0;
    width: 100%;
    overflow-x: auto;
    z-index: 2;
}

.category-bar .category-bar-item {
    padding: 10px;
    background-color: #eee;
    cursor: pointer;
    display: flex;
    justify-content: center;
    min-width: 120px;
    color: #121212;
}

.category-bar .category-bar-item.active {
    background-color: #222;
    color: #fff;
}

.category-bar-container {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    align-items: center;
}

.pages .row {
    border-bottom: 2px solid #ddd;
    padding: .2em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.prev {
    border-radius: .5em 0em 0 .5em;
    cursor: pointer;
}

.next {
    border-radius: 0 .5em .5em 0;
    cursor: pointer;
}

.prev:hover,
.next:hover {
    background-color: #222;
    color: #fff;
}

.product-price-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.product-item-discount {
    margin-bottom: 10px;
}