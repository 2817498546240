/*  create css for a basic contact page */

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
}

.contact h1 {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.contact p {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.contact a {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: #000;
    text-decoration: none;
}

.contact a:hover {
    color: #000;
}

.contact a:active {
    color: #000;
}

.contact a:visited {
    color: #000;
}




.form-group input {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    padding: .4rem 1rem;
    border: none;
    border-bottom: 1px  solid #959595;
    border-radius: 0px;
    width: 100%;
}

.form-group textarea {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
    padding: .4rem 1rem;
    border: none;
    border-bottom: 1px solid #959595;
    border-radius: 0px;
    width: 100%;
}

.form-group button {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 5px;
    width: 100%;
    background-color: #000;
    color: #fff;
    cursor: pointer;
}

.form-group button:hover {
    background-color: #fff;
    color: #000;
}

.banner-img {
    width: 100%;
    height: 400px;
    overflow: hidden;
    background-image: url('https://picsum.photos/2000');
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    position: relative;
}

.banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    text-align: center;
}

.banner-text h1 {
    font-size: 5rem;
}

.contact-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.contact-detail {
    display: flex;
    gap: 15px;
    padding: .2em;
}

.contact-detail-type {
    font-size: 1.5rem;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;

}
@media (max-width: 767px) {
    .contact-detail-type {
        font-size: 1rem;
    }
    .contact-detail-value {
        font-size: 1rem;
    }
}
.contact-detail-value {
    font-size: 1.5rem;
    color: #000;
    text-decoration: none;
}

.contact-title {
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #000;
}